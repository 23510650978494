import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import PageTeam from './pages/company/pageTeam';
import PageAboutus from './pages/company/pageAboutus';
import IndexModernSaas from './pages/index/indexModernSaas';
import ContactOne from './pages/contact/contactOne';
import Blog from './pages/blog/blog';
import BlogDetail from './pages/blog/blogDetail';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<IndexModernSaas />} />
                <Route exact path="/contact" element={<ContactOne />} />
                <Route exact path="/about" element={<PageAboutus />} />
                <Route exact path="/blog" element={<Blog />} />
                <Route exact path="/blog-detail/:id" element={<BlogDetail />} />
                <Route exact path="/team" element={<PageTeam />} />
            </Routes>
        
        </BrowserRouter>
    )
}
